import React from "react";
import Contacts from "components/contacts/Contacts";
import Oportunities from "components/oportunities/Oportunities";
import Services from "components/services";
import Img from "gatsby-image";
import HTMLReactParser from "html-react-parser";

const Concessionario = (props) => {
  const {
    bikes,
    data: {
      concessionario: {
        services,
        contactos,
        concessionarios: {
          title,
          subtitle,
          text,
          subMarcas,
          marcas,
          fachada,
          formEmailContacto,
          formEmailServicos,
          matterportLink
        }
      }
    }
  } = props;

  return (
    <main className="main">
      <div className="section-banner">
        <Img
          fluid={fachada?.localFile?.childImageSharp.fluid}
          alt={fachada?.altText}
        />
      </div>
      <div className="container conce-intro">
        <div className="conce-intro-text">
          <h1>{title}</h1>
          <h2>{subtitle}</h2>
          {text && HTMLReactParser(text)}
        </div>
        <ul className="conce-intro-brands">
          {marcas &&
            marcas.map((logo, index) => {
              return (
                <li key={index}>
                  <img src={logo?.localFile.url} alt={logo?.altText} />
                </li>
              );
            })}
        </ul>
        <ul className="conce-intro-brands conce-intro-brands--sub">
          {subMarcas &&
            subMarcas.map((logo, index) => {
              return (
                <li key={index}>
                  <img src={logo?.localFile.url} alt={logo?.altText} />
                </li>
              );
            })}
        </ul>
      </div>
      {matterportLink && (
        <div className="container frame">
          <iframe
            frameBorder="0"
            allowFullScreen
            allow="xr-spatial-tracking"
            src={matterportLink}
            title="Ver Salão 360"
          ></iframe>
        </div>
      )}
      <Services
        data={services}
        isConc
        formEmailServicos={formEmailServicos}
        place={title}
      />
      <Oportunities
        bikes={bikes}
        preLocation={props.data.concessionario.title}
      />
      <Contacts
        data={contactos}
        formEmailContacto={formEmailContacto}
        place={title}
      />
    </main>
  );
};

export const query = graphql`
  query concessionario($id: String) {
    concessionario: wpPost(id: { eq: $id }) {
      services {
        serviceFormId
        serviceTitleForm
        serviceIcon {
          altText
          localFile {
            url
          }
        }
        serviceTitle
        serviceText
        serviceImagem {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
      contactos {
        addMap
        contAddress
        contLatitude
        contLongitude
        contTextEmail
        linkMapsConcessionario
        contTextSchedule
        contTitle
        contEmail {
          email
        }
        contPhones {
          phone
        }
      }
      concessionarios {
        matterportLink
        formEmailContacto
        formEmailServicos
        fachada {
          altText
          localFile {
            childImageSharp {
              fluid(maxWidth: 2400, quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        subtitle
        text
        title
        subMarcas {
          altText
          localFile {
            url
          }
        }
        marcas {
          altText
          localFile {
            url
          }
        }
      }
      title
    }
  }
`;

export default Concessionario;
